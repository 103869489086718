body {
  background-image: url(http://subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/squares.png)
}

.box {
  display: inline-block;
  border: 1px solid black;
  width: 15px;
  height: 15px;
  margin-left: -1px;
  margin-bottom: -1px;
}

.off {
  background-color: lightgray;
}

.on {
  background-color: green;
}

.box:hover {
  background: #00CCFF;
}

.center {
  margin: auto;
  display: table;
  margin-top: 10px;
}

.grid {
  width: 150px;
  line-height: 0;
  margin: auto;
  box-shadow: 0px 0px 20px white;
  margin-top: 20px;
}

h1,
h2,
h3,
h4 {
  color: white;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 10px;
}

h4 {
  margin-top: 0px;
}

/* added to enhance bootstrap */
.btn-toolbar button {
  margin: 0 3px;
}
